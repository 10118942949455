{
  "unsubscribe_title": "Unsubscribe from emails",
  "unsubscribe_message": "Sorry to see you go.",
  "unsubscribe": "Unsubscribe",
  "cancel": "Cancel",
  "passwordResetSuccess": "Your password has been changed successfully.",
  "passwordResetError": "Unfortunately, there was a problem",
  "passwordResetTitle": "Set a new password",
  "new_password": "New password",
  "confirm_password": "Confirm new password",
  "save": "Save",
  "error": "Unfortunately, there was a problem",
  "unsubscribeSuccess": "The action was successful",
  "emailChangeLoading": "Email change loading...",
  "emailChangeSuccess": "The email was successfully changed",
  "emailChangeError": "Unfortunately, there was a problem changing the email"
}

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Hundeo App` : `Hundeo App`
    }}</template>
  </metainfo>
  <header />
  <router-view />
  <footer />
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
};
</script>

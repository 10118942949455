{
  "unsubscribe_title": "E-Mails abbestellen",
  "unsubscribe_message": "Schade, dass sie sich abmelden wollen.",
  "unsubscribe": "Abbestellen",
  "cancel": "Abbrechen",
  "passwordResetSuccess": "Dein Password wurde erfolgreich geändert.",
  "passwordResetError": "Leider gab es ein Problem",
  "passwordResetTitle": "Neues Passwort festlegen",
  "new_password": "Neues Passwort",
  "confirm_password": "Neues Passwort bestätigen",
  "save": "Speichern",
  "error": "Leider gab es ein Problem",
  "unsubscribeSuccess": "Die Aktion war erfolgreich",
  "emailChangeLoading": "E-Mail Änderung lädt...",
  "emailChangeSuccess": "Die E-Mail wurde erfolgreich geändert.",
  "emailChangeError": "Leider gab es ein Problem bei der Änderung der E-Mail"
}

import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("/src/views/Home.vue");
const ResetPassword = () => import("/src/views/PasswordReset.vue");
const UnsubscribeEmails = () => import("/src/views/UnsubscribeEmails.vue");
const ChangeEmail = () => import("/src/views/ChangeEmail.vue");

const routes = [
  {
    path: "/reset_password/:token/:id",
    component: ResetPassword,
  },
  {
    path: "/unsubscribe/:id",
    component: UnsubscribeEmails,
  },
  {
    path: "/change_email/:token/:id",
    component: ChangeEmail,
  },
  {
    path: "/:pathMatch(.*)*",
    component: Home,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
